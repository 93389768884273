
import sensorTanque from '../../assets/images/devices/TANQUE.svg';


export const parseDevices = (devices) => {
    let _parseDevices = [];
    


   
    Object.keys(devices).forEach((device,i )=> {
        const time = devices[device]["time"]
        _parseDevices.push(
            {
                id: device,
                name: devices[device]["name"] || "",
                brand: devices[device]["brand"] || "",
                model: devices[device]["brand"] || "",
                dir: devices[device]["brand"] || "",
                time,
                img: sensorTanque
            }
        )
    });

    return _parseDevices;
};