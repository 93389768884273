import * as actionTypes from '../constantes/tickets';


export const actionFetchTicket = (device, start, end) => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_UDS,
  device, start, end
});

export const actionFetchRangeTicketSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_SUCCESS,
  payload,
});

export const actionFetchRangeTicketFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_FAILURE,
});


export const actionFetchAddTicket = (payload) => ({
  type: actionTypes.FETCHING_TICKET_ADD,
  payload
});

export const actionFetchAddTicketSuccess = payload => ({
  type: actionTypes.FETCHING_TICKET_ADD_SUCCESS,
  payload,
});

export const actionFetchAddTicketFailure = () => ({
  type: actionTypes.FETCHING_TICKET_ADD_FAILURE,
});