import {request} from './request';


export const activityDeviceRange = (device, start, end) => {

    const data = {
        start,
        end
    };

    return request('get', `devices/${device}/activity_balance`, data)
        .then(response => response.data || [])
        .catch(error => error)
};


export const activityDeviceDaily = (device, start, end) => {

    const data = {
        start,
        end
    };

    return request('get', `devices/${device}/activity_daily_last`, data)
        .then(response => response.data.activity_daily_last || [])
        .catch(error => error)
};
