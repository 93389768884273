import * as actionTypes from '../constantes/uds';

const initialState = {
  payload: [],
  loanding: false,
};

export const reducerFetchUds = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITY_UDS:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_ACTIVITY_UDS_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_ACTIVITY_UDS_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};
