  export const FETCHING_ACTIVITY_UDS           = 'FETCHING_ACTIVITY_UDS';
  export const FETCHING_ACTIVITY_UDS_SUCCESS   = 'FETCHING_ACTIVITY_UDS_SUCCESS';
  export const FETCHING_ACTIVITY_UDS_FAILURE   = 'FETCHING_ACTIVITY_UDS_FAILURE';

  export const FETCHING_UDS_ADD_DEVICE   = 'FETCHING_UDS_ADD_DEVICE';
  export const FETCHING_UDS_ADD_SUCCESS  = 'FETCHING_UDS_ADD_SUCCESS';
  export const FETCHING_UDS_ADD_FAILURE  = 'FETCHING_UDS_ADD_FAILURE';


  export const FETCHING_UDS_EDIT          = 'FETCHING_UDS_EDIT';
  export const FETCHING_UDS_EDIT_SUCCESS  = 'FETCHING_UDS_EDIT_SUCCESS';
  export const FETCHING_UDS_EDIT_FAILURE  = 'FETCHING_UDS_EDIT_FAILURE';