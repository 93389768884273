import * as actionTypes from '../constantes/uds';


export const actionFetchUds = () => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS,
});

export const actionFetchRangeUdsSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS_SUCCESS,
  payload,
});

export const actionFetchRangeUdsFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS_FAILURE,
});

export const actionFetchAddUds = (payload) => ({
  type: actionTypes.FETCHING_UDS_ADD_DEVICE,
  payload
});

export const actionFetchAddUdsSuccess = payload => ({
  type: actionTypes.FETCHING_UDS_ADD_SUCCESS,
  payload,
});

export const actionFetchAddUdsFailure = () => ({
  type: actionTypes.FETCHING_UDS_ADD_FAILURE,
});


export const actionEditUds = (payload) => ({
  type: actionTypes.FETCHING_UDS_EDIT,
  payload
});

export const actionFetchEditUdsSuccess = payload => ({
  type: actionTypes.FETCHING_UDS_EDIT_SUCCESS,
  payload,
});

export const actionFetchEditUdsFailure = () => ({
  type: actionTypes.FETCHING_UDS_EDIT_FAILURE,
});
