import { request } from "./request";
import { dateToUnix } from "../helpers/dates";

export const activityTicketsRange = (device, start, end) => {
  const data = { start, end };

  if (device) {
    return request("get", `udss/${device}/activity`, data)
      .then((response) => response.data.activity || [])
      .catch((error) => error);
  }
};

export const apiTicketAdd = (ticket) => {
  const { device, importe, serv, preciou, date, total, cantidad, iva, metodo="PUE", forma = "01" } = ticket;

  const tickets = {
    udsId: device,
    tickets: [
      {
        importe,
        met_pago: metodo,
        serv,
        total,
        iva,
        forma_pago: forma,
        precio: preciou,
        litros: cantidad,
        time: dateToUnix(date),
      },
    ],
  };

  return request("put", `udss/${device}/subir_tickets`, {}, tickets)
    .then((response) => response.data.activity || [])
    .catch((error) => error);
};

export const apiInvoice = (data) => {

  const {cfdi = "G03", rfc, receptor, udsId, fecha } = data;

  
  const response = {
    fecha,
    cfdi,
    rfc,
    receptor,
    tickets: [data],
    udsId
  };

  return request("put", `udss/${udsId}/facturar_tickets`, {}, response)
    .then((response) => response.data)
    .catch((error) => error);
};


export const apiDelete = (data) => {
  const {udsId, fecha_fact, serv } = data;
  
  const response ={
      "fecha": fecha_fact,
      "tickets": [serv],
      udsId
  }

  return request("put", `udss/${udsId}/cancelar_fact`, {}, response)
    .then((response) => response.data)
    .catch((error) => error);
};


export const apiDownloadInvoice = (data) => {

  const {fecha_fact, uuid, udsId } = data;
  console.log('apiDownloadInvoice', data)

  const response = {
    "fecha": fecha_fact,
    uuid,
    udsId
  };

  const headers = {
    'Accept': 'application/zip'
  }
  const responseType = 'arraybuffer'
  return request("put", `udss/${udsId}/solicitar_fact `, {}, response, headers, responseType)
    .then((response) => response.data)
    .catch((error) => new Error(error));
};