import {FETCHING_DEVICE_ADD_DEVICE, FETCHING_DEVICE_EDIT_DEVICE, FETCHING_DEVICES_LIST} from '../constantes/devices';
import {FETCHING_ACTIVITY_DAILY_DEVICE, FETCHING_ACTIVITY_RANGE_DEVICE} from '../constantes/activity';
import {FETCHING_ACTIVITY_TICKET_UDS, FETCHING_TICKET_ADD} from '../constantes/tickets';
import {FETCHING_ACTIVITY_UDS, FETCHING_UDS_ADD_DEVICE, FETCHING_UDS_EDIT} from '../constantes/uds';
import {FETCHING_CUSTOMERS, ADD_CUSTOMER, EDIT_CUSTOMER, DELETE_CUSTOMER} from '../constantes/customers';


import CONSTANTES_LOGIN from '../constantes/session';
import {sagaActivityDaily, sagaActivityRange} from './activity';
import {sagaDeviceAdd, sagaDevices, sagaDeviceUpdate} from './devices';
import {sagaActivityTicketsRange, sagaTicketAdd} from './tickets';
import {sagaActivityUds, sagaUdsAdd, sagaUdsEdit} from './uds'
import {sagaGetCustomers, sagaAddCustomer, sagaDeleteCustomer, sagaEditCustomer} from './customers'

import {sagaLoginUser} from './session';
import {takeEvery} from 'redux-saga/effects';


export default function* functionPrimaria() {
  yield takeEvery(CONSTANTES_LOGIN.FETCHING_USER_LOGIN, sagaLoginUser);
  yield takeEvery(FETCHING_DEVICES_LIST, sagaDevices);
  yield takeEvery(FETCHING_DEVICE_ADD_DEVICE, sagaDeviceAdd);
  yield takeEvery(FETCHING_DEVICE_EDIT_DEVICE, sagaDeviceUpdate);
  yield takeEvery(FETCHING_ACTIVITY_RANGE_DEVICE, sagaActivityRange);
  yield takeEvery(FETCHING_ACTIVITY_DAILY_DEVICE, sagaActivityDaily);
  yield takeEvery(FETCHING_ACTIVITY_TICKET_UDS, sagaActivityTicketsRange);
  yield takeEvery(FETCHING_ACTIVITY_UDS, sagaActivityUds);
  yield takeEvery(FETCHING_UDS_ADD_DEVICE, sagaUdsAdd);
  yield takeEvery(FETCHING_TICKET_ADD, sagaTicketAdd);
  yield takeEvery(FETCHING_UDS_EDIT, sagaUdsEdit);

  yield takeEvery(FETCHING_CUSTOMERS, sagaGetCustomers);
  yield takeEvery(ADD_CUSTOMER, sagaAddCustomer);
  yield takeEvery(EDIT_CUSTOMER, sagaEditCustomer);
  yield takeEvery(DELETE_CUSTOMER,sagaDeleteCustomer);

}
