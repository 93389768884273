import * as actionTypes from '../constantes/customers';

export const actionFetchCustomers = () => ({
  type: actionTypes.FETCHING_CUSTOMERS,
});

export const actionFetchCustomersSuccess = payload => ({
  type: actionTypes.FETCHING_CUSTOMERS_SUCCESS,
  payload,
});

export const actionFetchCustomersFailure = () => ({
  type: actionTypes.FETCHING_CUSTOMERS_FAILURE,
});


export const actionFetchAddCustomer = (payload) => ({
  type: actionTypes.ADD_CUSTOMER,
  payload
});

export const actionFetchAddCustomerSuccess = payload => ({
  type: actionTypes.ADD_CUSTOMER_SUCCESS,
  payload,
});

export const actionFetchAddCustomerFailure = () => ({
  type: actionTypes.ADD_CUSTOMER_FAILURE,
});


export const actionFetchDeleteCustomer = (payload) => ({
  type: actionTypes.DELETE_CUSTOMER,
  payload
});

export const actionFetchDeleteCustomerSuccess = payload => ({
  type: actionTypes.DELETE_CUSTOMER_SUCCESS,
  payload,
});

export const actionFetchDeleteCustomerFailure = () => ({
  type: actionTypes.DELETE_CUSTOMER_FAILURE,
});

export const actionFetchEditCustomer = (payload) => ({
  type: actionTypes.EDIT_CUSTOMER,
  payload
});

export const actionFetchEditCustomerSuccess = payload => ({
  type: actionTypes.EDIT_CUSTOMER_SUCCESS,
  payload,
});

export const actionFetchEditCustomerFailure = () => ({
  type: actionTypes.EDIT_CUSTOMER_FAILURE,
});