import history from "../../routes/redirect/history";
import axios from "axios";

export const request = (
  method = "get",
  url,
  params = {},
  data = {},
  headers = {},
  responseType
) => {
  return axios(`${process.env.REACT_APP_SERVER}${url}`, {
    method,
    params,
    data,
    withCredentials: true,
    headers,
    responseType,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        history.push("/auth/signin/");
        window.location.reload();
      }
      return new Error(error);
    });
};
