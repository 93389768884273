import {call, put} from 'redux-saga/effects';
import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {apiUdsDetails, apiUdsAdd,apiUdsUpdate } from '../api/uds';

import {
  actionFetchRangeUdsSuccess,
  actionFetchRangeUdsFailure,

  actionFetchUds,
  actionFetchAddUdsSuccess,
  actionFetchAddUdsFailure,

  actionFetchEditUdsSuccess,
  actionFetchEditUdsFailure
} from '../actions/uds';

export function* sagaActivityUds() {
  try {
    yield put(actionFetchData());
    let tickets = yield call(apiUdsDetails);
    yield put(actionFetchRangeUdsSuccess(tickets));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchRangeUdsFailure());
    yield put(actionFetchDataFailure());
  }
}

export function* sagaUdsAdd({payload}) {
  try {
    yield put(actionFetchData());
    let device = yield call(apiUdsAdd, payload);
    yield put(actionFetchAddUdsSuccess(device));
    yield put(actionFetchUds());
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchAddUdsFailure());
    yield put(actionFetchDataFailure());

  }
}



export function* sagaUdsEdit({payload}) {
  try {
    yield put(actionFetchData());
    let uds = yield call(apiUdsUpdate, payload);
    yield put(actionFetchEditUdsSuccess(uds));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchEditUdsFailure());
    yield put(actionFetchDataFailure());

  }
}