import * as actionTypes from '../constantes/customers';

const initialStateRange = {
  payload: [],
  loanding: false,
};

export const reducerFetchCustomers = (state = initialStateRange, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_CUSTOMERS:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_CUSTOMERS_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_CUSTOMERS_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};
