import { call, put } from 'redux-saga/effects';
import { getCustomers, addCustomer, deleteCustomer, editCustomer } from '../api/customers';

import {
  actionFetchData,
  actionFetchDataFailure,
  actionFetchDataSuccess,
} from '../actions/request';

import {
  actionFetchCustomers,
  actionFetchCustomersSuccess,
  actionFetchCustomersFailure,

  actionFetchAddCustomerSuccess,
  actionFetchAddCustomerFailure,

  actionFetchDeleteCustomerSuccess,
  actionFetchDeleteCustomerFailure,

  actionFetchEditCustomerSuccess,
  actionFetchEditCustomerFailure
} from '../actions/customers';


export function* sagaGetCustomers() {
  try {
    yield put(actionFetchData());
    const  clients = yield call(getCustomers);
    yield put(actionFetchCustomersSuccess(clients));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchCustomersFailure());
    yield put(actionFetchDataFailure());
  }
}

export function* sagaAddCustomer({payload}) {
  try {
    yield put(actionFetchData());
    const client = yield call(addCustomer, payload);
    yield put(actionFetchAddCustomerSuccess(client));
    yield put(actionFetchCustomers());
  } catch (error) {
    yield put(actionFetchAddCustomerFailure());
    yield put(actionFetchDataFailure());
  }
}

export function* sagaDeleteCustomer({payload}) {
  try {
    yield put(actionFetchData());
    const client = yield call(deleteCustomer, payload);
    yield put(actionFetchDeleteCustomerSuccess(client));
    yield put(actionFetchCustomers());
  } catch (error) {
    yield put(actionFetchDeleteCustomerFailure());
    yield put(actionFetchDataFailure());
  }
}

export function* sagaEditCustomer({payload}) {
  try {
    yield put(actionFetchData());
    const client = yield call(editCustomer, payload);
    yield put(actionFetchEditCustomerSuccess(client));
    yield put(actionFetchCustomers());
  } catch (error) {
    yield put(actionFetchEditCustomerFailure());
    yield put(actionFetchDataFailure());
  }
}