import {call, put} from 'redux-saga/effects';
import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {activityTicketsRange, apiTicketAdd} from '../api/tickets';

import {
  actionFetchTicket,
  actionFetchRangeTicketSuccess,
  actionFetchRangeTicketFailure,
  actionFetchAddTicketSuccess,
  actionFetchAddTicketFailure
} from '../actions/tickets';

export function* sagaActivityTicketsRange({device, start, end}) {
  try {
    yield put(actionFetchData());
    let tickets = yield call(activityTicketsRange, device, start, end);
    yield put(actionFetchRangeTicketSuccess(tickets));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchRangeTicketFailure());
    yield put(actionFetchDataFailure());
  }
}



export function* sagaTicketAdd({payload}) {
  try {
    yield put(actionFetchData());
    let ticket = yield call(apiTicketAdd, payload);
    yield put(actionFetchAddTicketSuccess(ticket));
    yield put(actionFetchTicket());
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchAddTicketFailure());
    yield put(actionFetchDataFailure());
  }
}