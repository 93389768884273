export const parseCustomers = (customers) => {
    let newCustomers = [];

    Object.keys(customers).forEach((customer) => {
        
        const temp = customers[customer];

        newCustomers.push({
            'rfc': customer,
            'razon': temp.razon,
            'calle': temp.calle,
            'numero': temp.numero,
            'colonia': temp.colonia,
            'ciudad': temp.ciudad,
            'municipio': temp.municipio,
            'estado': temp.estado,
            'postal': temp.postal,
            'pais': temp.pais,
            'telefono': temp.telefono,
            'correo': temp.correo,
            'notas': temp.notas,
        })
    });
    return newCustomers;
};
