export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const FETCHING_CUSTOMERS_SUCCESS = 'FETCHING_CUSTOMERS_SUCCESS';
export const FETCHING_CUSTOMERS_FAILURE = 'FETCHING_CUSTOMERS_FAILURE';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';


export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';


export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';