import { request } from './request'; 
import { parseDevices } from '../helpers/uds';
export const apiUdsDetails = () => {
    return request('get', `udss/details`, {})
        .then((response) => parseDevices(response.data) || [])
        .catch(error => [])
};

export const apiUdsUpdate = (data) => {
    return request('put', `udss/${data.id}/details`,data)
        .then((response) => response.data)
        .catch(error => error)
};


export const apiUdsAdd = (id) => {
    return request('put', `udss/${id}`,{})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiUdsDelete = (id) => {
    return request('delete', `udss/${id}`,{})
        .then((response) => response.data)
        .catch(error => error)
};
