import { request } from "./request";
import { parseCustomers } from "../helpers/customers";
export const getCustomers = () => {
  return request("get", `clients/`, {})
    .then(({ data }) => parseCustomers(data))
    .catch((error) => {
      console.log("ERROR", error);
      return [];
    });
};

export const addCustomer = (data) => {
  return request("put", "clients/add", {}, data)
    .then((response) => response || {})
    .catch((error) => error);
};

export const deleteCustomer = (data) => {
  return request("delete", `clients/del`, {}, data)
    .then((response) => response || {})
    .catch((error) => error);
};

export const editCustomer = (data) => {
  return request("put", "clients/add", {}, data)
    .then((response) => response || {})
    .catch((error) => error);
};
